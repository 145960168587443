import {createContext, Dispatch, ReactNode, SetStateAction, useCallback, useContext, useRef, useState} from "react";

import {t} from "@lingui/macro";
import {useRouter} from "next/router";
import useSWR from "swr";

import Loader from "components/loader";
import {MAP_MODE} from "components/map/controls/darkmode-btn/dark-mode-btn";
import StaticPage from "components/static-page";
import {Container} from "components/style";
import Theme from "context/theme";
import {TrackerResponse, TrackerState} from "types/types";
import {initDateTimeLocale} from "utils/datetime-utils";
import {apiFetcher, defaultNotFound, httpFetcher} from "utils/utils";
import {extractUuidFromUrl} from "utils/uuid";

// load environment variables once and pass down via components
const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY || "";
const ROUTING_API = process.env.NEXT_PUBLIC_ROUTING_API || "";
const ACTIVE_UPDATE_INTERVAL = Number(process.env.NEXT_PUBLIC_UPDATE_ACTIVE_TRACKER_INTERVAL) || 5000;
const QUEUED_UPDATE_INTERVAL = Number(process.env.NEXT_PUBLIC_UPDATE_QUEUED_TRACKER_INTERVAL) || 5000;

interface ITrackerContext {
  mapsAPIKey: string;
  routingAPI: string;

  isActive: boolean;
  isLoading: boolean;
  trackerID: string | string[] | undefined;
  data: TrackerResponse | undefined;
  mapMode: MAP_MODE | undefined;
  setMapMode: any;
}

const TrackerContext = createContext<ITrackerContext>({
  mapsAPIKey: "",
  routingAPI: "",

  isActive: false,
  isLoading: false,
  trackerID: "",
  data: undefined,
  mapMode: undefined,
  setMapMode: null,
});

interface TrackerContextProviderProps {
  id: string | string[] | undefined;
  children: ReactNode;
  startPolling: boolean;
  setStartPolling: Dispatch<SetStateAction<boolean>>;
}

const TrackerProvider = ({ id, children, startPolling, setStartPolling }: TrackerContextProviderProps) => {
  const router = useRouter();

  const queuedStateRetryCount = useRef<number>(0);

  const [mapMode, setMapMode] = useState<any>();

  const refreshInterval = useCallback(
    (latestData) => {
      if (!startPolling) {
        return 0;
      }

      switch (latestData?.state) {
        case TrackerState.ACTIVE:
          return ACTIVE_UPDATE_INTERVAL;
        case TrackerState.QUEUED:
          if (!latestData?.predicted_delivery) {
            const delayInSeconds = 2 ** queuedStateRetryCount.current;
            const delayInMilliSeconds = delayInSeconds * 1000;

            if (delayInMilliSeconds < QUEUED_UPDATE_INTERVAL) {
              queuedStateRetryCount.current += 1;

              return delayInMilliSeconds;
            }
          }
          return QUEUED_UPDATE_INTERVAL;
        default:
          return 0;
      }
    },
    [startPolling]
  );

  const { data, error } = useSWR<TrackerResponse>(id && `trackers/${id}/public/`, httpFetcher(router.locale), {
    onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
      // on error retry 3 time before timing out
      if (retryCount >= 3) return;
      setTimeout(() => revalidate({ retryCount }), 5000);
    },
    dedupingInterval: 1000,
    onSuccess: async (data) => {
      setStartPolling(true);
      if (data?.language) {
        await initDateTimeLocale(data.language);
      }
    },
    refreshInterval,
  });

  const accountId = extractUuidFromUrl(data?.account);

  const { data: theme, error: themeError } = useSWR(accountId && `/api/themes/?account=${accountId}`, apiFetcher);

  const redirectNotFound = error?.status || (data && data?.tasks.length === 0);
  const isActive = data?.state === TrackerState.ACTIVE;
  const loadingApp = error === undefined && data === undefined;

  if (redirectNotFound || !id) {
    return defaultNotFound();
  }

  if ((!data && !error) || loadingApp || (!theme && !themeError)) {
    return <StaticPage icon={<Loader />} description={t`Loading Tracking details`} />;
  }

  const contextValue = {
    mapsAPIKey: GOOGLE_API_KEY,
    routingAPI: ROUTING_API,

    isActive: isActive,
    isLoading: loadingApp,
    trackerID: id,
    data: data,
    mapMode: mapMode,
    setMapMode: setMapMode,
  };

  return (
    <Theme theme={theme || {}}>
      <TrackerContext.Provider value={contextValue}>
        <Container>{children}</Container>
      </TrackerContext.Provider>
    </Theme>
  );
};

const useTrackerContext = () => useContext(TrackerContext);
export {
  GOOGLE_API_KEY,
  ROUTING_API,
  ACTIVE_UPDATE_INTERVAL,
  QUEUED_UPDATE_INTERVAL,
  TrackerContext,
  TrackerProvider,
  useTrackerContext,
};
